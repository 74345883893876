/// global
import { SHARED_MODEL, SHARED_UTILS, state } from ":mods";
import { ANavigate, NavigateOptions } from "./models";
import { SetParams, useSearchParams, useParams } from "@solidjs/router";

export const $route = state.createGlobal<ANavigate>(undefined);
export const $route_params = state.createGlobal<{
  params: SetParams;
  options?: Partial<Omit<NavigateOptions, "afterNavigate">>;
}>(undefined);

/** i find 3 to be the sweet spot to detect this, configure it however you like. */
const max_watch_routes = 5; // when to start paying attention to
const max_route_occurance = 3; // how many times is the occurance of /auth for example is allowed
const max_window_ms = 100; // check route occurance if difference between calls or navigations is less than this max_window_ms
let last_update_interval = 0;
let route_tracker: string[] = [];
let route_error = undefined as string;
/** detect multiple repetetive redirects to prevent unnecessary navigations. can be manipulated through max_redirects */
function WillNavigationCauseError(to: string, pathname: string) {
  if (route_tracker.length < max_watch_routes) {
    route_tracker.push(to);
  }
  // console.log("WillNavigationCauseError :: ", to, pathname, route_tracker);
  let error: string = undefined;
  if (to === pathname) {
    error = `disallow navigation to same pathname preventing infinite redirects to ${to}`;
  } else {
    if (route_tracker.length >= max_watch_routes) {
      const now_ms = Date.now();
      const time_diff_ms = now_ms - last_update_interval;
      last_update_interval = now_ms;
      if (time_diff_ms <= max_window_ms) {
        const f = route_tracker.filter((d) => d === to);
        if (f.length >= max_route_occurance) {
          error = ` detected multiple reroutes without loading any route at ${to} \n`;
          error += `tracked routes :: ${route_tracker.join(" -> ")}`;
        } else {
          // console.log("route tracker has shifted ", route_tracker);
          route_tracker.shift();
        }
        // console.log("filter is ", f);
      }
      // console.log("time diff is ", last_update_interval, time_diff_ms);
    }
  }
  // console.log("route tracker is ", route_tracker);
  if (!error) {
    // route_tracker.push(to);
  } else {
    console.warn(error);
  }
  route_error = error;
  return error;
}

let global_base: string = undefined;
export const _route_actions = {
  setGlobalBaseFromGuard: (base: string) => {
    global_base = base;
  },
  getGobalRouteError: () => route_error,
};

export const hooks = {
  useSearchParams,
  useParams,
};

export const actions = {
  navigate(to: string, options?: Partial<NavigateOptions>) {
    const path = SHARED_UTILS.populateRouteHref({ path: to, base: global_base });
    const error = WillNavigationCauseError(path, window.location.pathname);
    if (!error) {
      $route.set({ to: path, options });
    }
    return error;
  },
  navigateDelta(delta: number) {
    $route.set({ delta });
  },
  navigateHref(to: SHARED_MODEL.PopulateRouteHrefProps, options?: Partial<NavigateOptions>) {
    const final_to = SHARED_UTILS.mergeRouteHref({ base: global_base }, to);
    const path = SHARED_UTILS.populateRouteHref(final_to);
    const error = WillNavigationCauseError(path, window.location.pathname);
    // console.log("pathname from HREF :: ", path, " :: ", window.location.pathname);
    if (!error) {
      $route.set({ to: path, options });
    }
    return error;
  },
  setSearchParams(params: SetParams, options?: Partial<NavigateOptions>) {
    $route_params.set({ params, options });
  },
};
